<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(onum)="data">
        <div>
          {{ data.item.dealer }}
        </div>
        <div class="font-small-2 text-primary">
          Sipariş No: {{ data.item.onum }}
        </div>
      </template>
      <template #cell(used_reports)="data">
        <div>
          <div class="font-weight-bold text-primary">
            {{ data.item.used_reports }} / {{ data.item.usable_reports }}
          </div>
          <div class="text-muted font-small-2">
            Rapor kullanımı
          </div>
        </div>
      </template>
      <template #cell(package_end)="data">
        <div v-if="data.item.package_end">
          {{ moment(data.item.package_end).format('LL') }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="primary"
            size="sm"
            :to="$route.path + '/goruntule/' + data.item.id"
          >
            <FeatherIcon icon="EyeIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BButton,
} from 'bootstrap-vue'

const tableName = 'dealer_orders'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'onum',
          label: 'SİPARİŞ NO',
        },
        {
          key: 'order_status',
          label: 'DURUM',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-200',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.onum AS onum`,
          'dealers.title AS dealer',
          'dealer_order_status.title AS order_status',
          'concat(members.name," ",members.surname) AS member',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['adminDealerOrders/dataList']
    },
    dataCounts() {
      return this.$store.getters['adminDealerOrders/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('adminDealerOrders/getDataList', this.dataQuery)
    },
  },
}
</script>
